const projects = [
  {
    id: 1,
    title: 'Rock Creek Wellness Website',
    slug: 'rock-creek-wellness',
    description: 'High-performance, SEO-optimized WordPress website for Rock Creek Wellness with custom CRM functionality.',
    longDescription: `Developed a responsive, user-friendly website using a custom WordPress theme compatible with Elementor. Implemented a custom CRM integration for lead management and targeted email marketing campaigns. Optimized website performance using advanced techniques, achieving high PageSpeed scores on both mobile and desktop.`,
    technologies: ['WordPress', 'PHP', 'JavaScript', 'CSS3', 'HTML5', 'Elementor'],
    features: [
      'Custom WordPress theme with Elementor compatibility',
      'Custom CRM integration for lead management',
      'Advanced performance optimizations',
      'Responsive design for all devices',
      'Custom functionalities for enhanced user experience',
    ],
    image: '/src/assets/images/rock-creek-wellness.jpg',
    link: 'https://rockcreekwellness.com',
    githubLink: 'https://github.com/yourusername/rock-creek-wellness',
    performanceMetrics: [
      'PageSpeed score: 86 on mobile, 98 on desktop',
      'Estimated load time: ~2.5s on mobile, ~1.2s on desktop',
      'First Contentful Paint: ~1.5s on mobile, ~0.8s on desktop',
      'Time to Interactive: ~3.5s on mobile, ~1.8s on desktop',
    ],
    businessImpact: ['35% increase in online appointment bookings', '40% improvement in average time on site', '25% reduction in bounce rate', '20% increase in organic search traffic'],
    duration: '8 weeks',
  },
  {
    id: 2,
    title: 'True View Security Solutions Website',
    slug: 'true-view-security-solutions',
    description: 'High-performance, SEO-optimized WordPress website for True View Security Solutions serving Greater Binghamton, NY and Northeast PA regions.',
    longDescription: `Designed and developed a responsive, user-friendly website using WordPress with Elementor. Implemented custom components including a carousel for testimonials and a tailored contact form to meet the client's specific needs. Focused on mobile responsiveness and SEO optimization to improve the company's online presence and lead generation capabilities.`,
    technologies: ['WordPress', 'Elementor', 'HTML', 'CSS', 'JavaScript'],
    features: [
      'Custom WordPress theme with Elementor',
      'Responsive design optimized for mobile and desktop',
      'Custom carousel for testimonials',
      'Tailored contact form for specific client needs',
      'SEO optimization for improved online visibility',
      'Performance optimizations for fast loading times',
    ],
    image: '/src/assets/images/true-view-security-solutions.jpg',
    link: 'https://trueviewsecuritysolutions.com',
    performanceMetrics: [
      'Mobile PageSpeed Insights: Performance 71, Accessibility 91, Best Practices 100, SEO 92',
      'Desktop PageSpeed Insights: Performance 91, Accessibility 92, Best Practices 100, SEO 92',
    ],
    businessImpact: ['Increased daily leads from 0 to 3-5 over a 60-day period', 'Improved online presence and visibility in Greater Binghamton and Northeast PA regions'],
    duration: '6 weeks',
  },
  {
    id: 3,
    title: 'Mia Aesthetics Decision Engine',
    slug: 'mia-aesthetics-decision-engine',
    description: 'Interactive web application for Mia Aesthetics to help users explore surgical options based on body areas and price ranges.',
    longDescription: `Developed an interactive decision engine application for Mia Aesthetics to enhance user engagement and streamline the process of exploring surgical options. The app features an interactive body map where users can select up to three areas of interest, specify their budget range, and receive tailored recommendations for surgeons and procedures.`,
    technologies: ['React', 'JavaScript', 'HTML', 'CSS', 'Webpack'],
    features: [
      'Interactive male and female body maps for selecting areas of interest',
      'Multi-select functionality allowing users to choose up to 3 body areas',
      'Price range slider for budget specification',
      'Dynamic filtering and display of relevant surgeons and procedures',
      'Responsive design for seamless use across devices',
      'Integration with JSON data source for flexible content management',
    ],
    image: '/src/assets/images/mia-aesthetics-decision-engine.jpg',
    link: 'https://stevenpelletier90.github.io/decision-engine-v2/',
    githubLink: 'https://github.com/stevenpelletier90/decision-engine-v2',
    performanceMetrics: ['Ongoing user engagement testing', 'Optimized for quick interactivity and response times'],
    businessImpact: [
      'Potential to significantly increase user engagement',
      'Streamlines the process of matching users with appropriate surgical options',
      'Enhances the user experience on the Mia Aesthetics platform',
    ],
    duration: '4-6 weeks',
  },
  {
    id: 4,
    title: 'San Antonio Prime Wellness Website',
    slug: 'san-antonio-prime-wellness',
    description: 'Comprehensive WordPress website redesign for San Antonio Prime Wellness, featuring custom theme, Elementor integration, and online dispensary.',
    longDescription: `Completely redesigned the San Antonio Prime Wellness website using a custom WordPress theme and Elementor. Implemented an online dispensary connected to a custom CRM (PatientNow). The site showcases various health and wellness services, including bioidentical hormone replacement therapy, weight loss programs, and nutritional supplements.`,
    technologies: ['WordPress', 'PHP', 'JavaScript', 'CSS3', 'HTML5', 'Elementor', 'Custom CRM Integration'],
    features: [
      'Custom WordPress theme with Elementor',
      'Online dispensary integration with custom CRM (PatientNow)',
      'Responsive design for all devices',
      'Showcase of various health and wellness services',
      'E-commerce functionality for nutritional supplements and CBD products',
      'SEO optimization for improved online visibility',
    ],
    image: '/src/assets/images/san-antonio-prime-wellness.jpg',
    link: 'https://sanantonioprimewellness.com/',
    performanceMetrics: ['Improved site load times', 'Enhanced user engagement metrics'],
    businessImpact: ['Streamlined patient engagement through online dispensary', 'Improved showcase of services leading to increased inquiries', 'Enhanced online presence in the San Antonio area'],
    duration: '8 weeks',
  },
  {
    id: 5,
    title: 'WIFH Medical Spa & Laser Aesthetics Center Website',
    slug: 'wifh-medical-spa',
    description: "Comprehensive WordPress website for WIFH, Atlanta's premier medical spa and laser aesthetics center, featuring extensive content management.",
    longDescription: `Developed a sophisticated WordPress website for WIFH, showcasing their range of medical spa and laser aesthetics services. The project involved handling a large volume of content, including detailed service descriptions and a regularly updated blog. The design emphasizes WIFH's experience, trustworthiness, and respected status in Atlanta's aesthetics industry.`,
    technologies: ['WordPress', 'PHP', 'JavaScript', 'CSS3', 'HTML5', 'Elementor'],
    features: [
      'Custom WordPress theme with Elementor',
      'Extensive content management system for services and blog',
      'Responsive design optimized for all devices',
      'Advanced SEO implementation for local and industry-specific keywords',
      'Integration of before/after galleries for treatments',
      'Appointment booking system',
    ],
    image: '/src/assets/images/wifh-medical-spa.jpg',
    link: 'https://www.wifh.com/',
    performanceMetrics: ['Improved page load times', 'Enhanced user engagement and time on site'],
    businessImpact: [
      'Increased online visibility for WIFH in the Atlanta area',
      'Improved conversion rates for appointment bookings',
      'Enhanced presentation of services leading to increased client inquiries',
    ],
    duration: '10 weeks',
  },
  {
    id: 6,
    title: 'AM Facial Plastics Website Redesign',
    slug: 'am-facial-plastics',
    description: 'Complete website redesign and SEO optimization for high-profile Los Angeles facial plastic surgeon, Dr. Moradzadeh.',
    longDescription: `Executed a comprehensive redesign of the AM Facial Plastics website for Dr. Moradzadeh, a prominent facial plastic surgeon in Los Angeles. The project involved transferring the site to WordPress with Elementor, implementing a custom theme, and revamping SEO and optimization practices. The new design effectively showcases Dr. Moradzadeh's expertise in both traditional and minimally invasive facial plastic surgery procedures.`,
    technologies: ['WordPress', 'PHP', 'JavaScript', 'CSS3', 'HTML5', 'Elementor', 'SEO Optimization'],
    features: [
      'Custom WordPress theme with Elementor for flexible content management',
      'Responsive design optimized for all devices',
      'Advanced SEO implementation focusing on local LA market and specific procedures',
      'Showcase of traditional and minimally invasive procedures',
      'Before and after galleries for various treatments',
      'Integration of patient testimonials and reviews',
      'Optimized page load times for improved user experience',
      'Easy-to-use appointment booking system',
    ],
    image: '/src/assets/images/am-facial-plastics.jpg',
    link: 'https://amfacialplastics.com/',
    performanceMetrics: ['Significant improvement in page load times', 'Enhanced mobile responsiveness scores', 'Improved search engine rankings for key procedures and local terms'],
    businessImpact: [
      'Increased visibility in the competitive Los Angeles facial plastic surgery market',
      'Higher conversion rates for consultation bookings',
      "Improved showcase of Dr. Moradzadeh's expertise leading to increased patient trust",
      'Better user engagement metrics, including longer time on site and lower bounce rates',
    ],
    duration: '12 weeks', // Estimated based on the scope of the project
  },
  {
    id: 7,
    title: 'The Real Dr. Miami Website',
    slug: 'the-real-dr-miami',
    description: 'Website development and maintenance for high-profile Miami plastic surgeon, Dr. Michael Salzhauer (Dr. Miami), integrating with PatientNow CRM.',
    longDescription: `Developed and maintained the website for Dr. Michael Salzhauer, widely known as Dr. Miami, a prominent plastic surgeon in Miami. The project involved creating a website that reflects Dr. Miami's unique brand and philosophy while effectively showcasing his services. Implemented integration with PatientNow CRM for streamlined patient management and marketing. The site emphasizes Dr. Miami's belief in the transformative power of plastic surgery and his mission to empower patients.`,
    technologies: ['WordPress', 'PHP', 'JavaScript', 'CSS3', 'HTML5', 'Elementor', 'PatientNow CRM Integration'],
    features: [
      'Custom WordPress theme with Elementor for dynamic content management',
      'Responsive design optimized for various devices',
      'Integration with PatientNow CRM for efficient patient management',
      "Showcase of Dr. Miami's wide range of plastic surgery procedures",
      'Before and after galleries highlighting successful transformations',
      "Social media integration to leverage Dr. Miami's significant online presence",
      'Blog section for sharing industry insights and patient stories',
      'Optimized for search engines with a focus on Miami and surrounding areas',
    ],
    image: '/src/assets/images/the-real-dr-miami.jpg',
    link: 'https://therealdrmiami.com/',
    performanceMetrics: ['Improved website loading speed and performance', 'Enhanced mobile user experience', 'Increased organic search visibility for key procedures'],
    businessImpact: [
      'Streamlined patient acquisition process through CRM integration',
      'Increased online appointment bookings',
      "Enhanced brand representation aligning with Dr. Miami's social media presence",
      'Improved patient education through comprehensive procedure information',
    ],
    duration: 'Ongoing maintenance and updates',
  },
  {
    id: 8,
    title: 'True View Security Solutions Website',
    slug: 'true-view-security-solutions',
    description: 'Website development for True View Security Solutions serving Greater Binghamton, NY and Northeast PA regions.',
    longDescription: `[Detailed description of the project, including main objectives and any unique challenges faced]`,
    technologies: ['WordPress', 'Elementor', 'HTML', 'CSS', 'JavaScript', '[Add any other technologies used]'],
    features: ['Responsive design optimized for mobile and desktop', 'Custom WordPress theme with Elementor', '[List other key features implemented]'],
    image: '/src/assets/images/true-view-security-solutions.jpg',
    link: 'https://trueviewsecuritysolutions.com',
    performanceMetrics: ['[Add specific performance metrics if available]', '[e.g., PageSpeed Insights scores, load times, etc.]'],
    businessImpact: ['[Add information about the impact on the clients business]', '[e.g., Increased leads, improved online visibility, etc.]'],
    duration: '[Project duration]',
  },
  {
    id: 9,
    title: 'Mia Aesthetics Website',
    slug: 'mia-aesthetics',
    description: 'Website development for Mia Aesthetics, a plastic surgery clinic with multiple locations.',
    longDescription: `[Detailed description of the project, including main objectives and any unique challenges faced]`,
    technologies: ['[List primary technologies used]'],
    features: ['Responsive design with a focus on mobile user experience', '[List other key features implemented]'],
    image: '/src/assets/images/mia-aesthetics.jpg',
    link: 'https://miaaesthetics.com/',
    performanceMetrics: ['[Add specific performance metrics if available]', '[e.g., Improvement in page load times, mobile conversion rates, etc.]'],
    businessImpact: ['[Add information about the impact on the clients business]', '[e.g., Increase in online consultation bookings, improved brand perception, etc.]'],
    duration: '[Project duration]',
  },
  {
    id: 10,
    title: 'Dr. Jeneby Plastic Surgery Website',
    slug: 'dr-jeneby-plastic-surgery',
    description: 'Website development for Dr. Thomas Jeneby, a plastic surgeon in San Antonio, TX.',
    longDescription: `[Detailed description of the project, including main objectives and any unique challenges faced]`,
    technologies: ['[List primary technologies used]'],
    features: ['[List key features implemented]', '[e.g., Virtual consultation tool, before-and-after gallery, etc.]'],
    image: '/src/assets/images/dr-jeneby-plastic-surgery.jpg',
    link: 'https://drjenebyplasticsurgery.com/',
    performanceMetrics: ['[Add specific performance metrics if available]', '[e.g., PageSpeed Insights scores, reduction in bounce rate, etc.]'],
    businessImpact: ['[Add information about the impact on the clients business]', '[e.g., Increase in consultation requests, improved patient education, etc.]'],
    duration: '[Project duration]',
  },
];

export default projects;
